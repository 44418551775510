@charset "UTF-8";

$breakpoint_sp: 768;
$breakpoint_tb: 1400;

@mixin sp {
  @media screen and (max-width: ($breakpoint_sp - 1px)) {
    @content;
  }
}

@mixin tb {
  @media screen and (max-width: ($breakpoint_tb + 0px)) {
    @content;
  }
}

@mixin pc {
  @media screen and (min-width: ($breakpoint_tb + 0px)) {
    @content;
  }
}

@mixin pc-type {
  @media screen and (min-width: ($breakpoint_sp + 0px)) {
    @content;
  }
}