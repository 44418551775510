@import '../mixin/_utility';

#search-modal {
  width: 960px;
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  #search-modal {
    width: 100%;
    padding: 0 20px; padding: 0 5.12820vw;
    padding-bottom: calc(180 / 390 * 100vw);
  }
}

#search-modal-form-inner {
  margin: 55px 0 0 0;
  display: table;
  border-top: solid 1px #C6C6C6;
  width: 100%;
}
.search-modal-form-row {
  display: table-row;
}
.search-modal-form-row-title,
.search-modal-form-row-body {
  display: table-cell;
  border-bottom: solid 1px #C6C6C6;
}
.search-modal-form-row-title {
  background-color: #F0F0F0;
  padding: 20px 25px;
  white-space: nowrap;
  min-width: 167px;
}
.search-modal-form-row-title-label {
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}
.search-modal-form-row-title-label-note {
  font-size: 10px;
  line-height: 1.5;
  color: #747474;
  margin-bottom: 2px;
}
.search-modal-form-row-body {
  padding: 10px;
  background-color: #fff;
  padding: 20px 30px;
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  #search-modal-form-inner {
    margin-top: 30px; margin-top: 7.69230vw;
    display: block;
  }
  .search-modal-form-row {
    display: block;
    padding: 24px 15px; padding: 6.15384vw 3.84615vw;
    border-bottom: solid 1px #C6C6C6;
  }
  .search-modal-form-row-title,
  .search-modal-form-row-body {
    display: block;
    border-bottom: none;
    padding: 0;
  }
  .search-modal-form-row-title {
    background-color: transparent;
    min-width: 0;
    display: flex;
    align-items: center;
    position: relative;
    &:before {
      content: '';
      display: block;
      background: $color_main;
      width: calc(3 / 390 * 100vw);
      height: calc(16 / 390 * 100vw);
      margin-right: var(--size-5);
      top: 0.05em;
      position: relative;
    }
    &:after {
      content: '';
      display: block;
      background: $color_accent;
      width: calc(3 / 390 * 100vw);
      height: calc(8 / 390 * 100vw);
      position: absolute;
      top: 0.05em;
      left: 0;
    }
  }
  .search-modal-form-row-title-label {
    font-size: 14px; font-size: 3.58974vw;
    flex-direction: row;
    align-items: flex-end;
    gap: 6px; gap: 1.53846vw;
    margin: -3px 0; margin: -0.76923vw 0;
    @include fz-vw(16);
    @include font-weight(semi);
    position: relative;
  }
  .search-modal-form-row-title-label-note {
    font-size: 10px; font-size: 2.56410vw;
    margin-bottom: 2px; margin-bottom: 0.51282vw;
  }
  .search-modal-form-row-body {
    margin-top: 14px; margin-top: 3.58974vw;
    @include sp {
      @include fz-vw(12);
    }
  }
}



#search-modal-form input[type="text"],
#search-modal-form input[type="date"],
#search-modal-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  display: block;
  width: 100%;
  background: #fff;
  border: solid 1px #C6C6C6;
  border-radius: 3px;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 1.5;
  padding: 8px 12px;
}
#search-modal-form select::-ms-expand {
  display: none;
}
#search-modal-form select {
  background-image: url(../../img/icon/icon_arrow-down_black.svg);
  padding-right: 33px;
  background-repeat: no-repeat;
  background-position: right 12px top 15px;
  background-size: 9px auto;
}

#search-modal-form input[type="text"]:disabled,
#search-modal-form input[type="date"]:disabled,
#search-modal-form select:disabled {
  color: #ddd;
  border-color: #ddd;
  opacity: 0.9;
  background-blend-mode: difference;
  cursor: not-allowed;
}

#search-modal-form select option:disabled {
  display: none;
}

@media screen and (max-width: 767px) {
  #search-modal-form input[type="text"],
  #search-modal-form input[type="date"],
  #search-modal-form select {
    border-radius: 3px; border-radius: 0.76923vw;
    font-size: 12px; font-size: 3.07692vw;
    padding: 8px 12px; padding: 2.05128vw 3.07692vw;
  }
  #search-modal-form select {
    padding-right: 33px; padding-right: 8.46153vw;
    background-position: right 12px top 15px; background-position: right 3.07692vw top 3.84615vw;
    background-size: 9px auto; background-size: 2.30769vw auto;
  }
}



.search-modal-form-row-body-rowgroup+.search-modal-form-row-body-rowgroup {
  margin-top: 16px;
}
.search-modal-form-row-body-rowgroup-title {
  font-size: 14px;
  line-height: 1.5;
  padding: 2px 0;
  margin: 0 0 6px 0;
  @include font-weight(bold);
}

@media screen and (max-width: 767px) {
  .search-modal-form-row-body-rowgroup+.search-modal-form-row-body-rowgroup {
    margin-top: 16px; margin-top: 4.10256vw;
  }
  .search-modal-form-row-body-rowgroup-title {
    font-size: 12px; font-size: 3.07692vw;
    padding: 2px 0; padding: 0.51282vw 0;
    margin-bottom: 6px; margin-bottom: 1.53846vw;
    @include fz-vw(14);
  }
}



#search-modal-form .search-modal-checkbox-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;
}
#search-modal-form .search-modal-checkbox-list-small {
  gap: 11px 14px;
  padding: 2px 0;
}
#search-modal-form .checkbox input[type="checkbox"] {
  display: none;
}
#search-modal-form .checkbox label {
  position: relative;
  display: block;
  padding-left: 25px;
  font-size: 14px;
  line-height: 1.5;
}
#search-modal-form .search-modal-checkbox-list-small .checkbox label {
  font-size: 14px;
  padding-left: 22px;
}
#search-modal-form .checkbox label::before,
#search-modal-form .checkbox label::after {
  content: "";
  display: block;
  position: absolute;
  -webkit-transition: .3s;
  transition: .3s;
}
#search-modal-form .checkbox label::before {
  width: 16px;
  height: 16px;
  border: solid 1px #C6C6C6;
  border-radius: 3px;
  background-color: #fff;
  top: 2px;
  left: 0;
}
#search-modal-form .search-modal-checkbox-list-small .checkbox label::before {
  width: 14px;
  height: 14px;
}
#search-modal-form .checkbox label::after {
  width: 6px;
  height: 10px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 3px;
  left: 5px;
  opacity: 0;
}
#search-modal-form .search-modal-checkbox-list-small .checkbox label::after {
  width: 4px;
  height: 8px;
  top: 4px;
  left: 5px;
}
#search-modal-form .checkbox input:checked+label::before {
  background-color: #00005C;
  border-color: #00005C;
}
#search-modal-form .checkbox input:checked+label::after {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  #search-modal-form .search-modal-checkbox-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 14px; gap: 3.58974vw;
  }
  #search-modal-form .search-modal-checkbox-list-small {
    gap: 11px 14px; gap: 2.82051vw 3.58974vw;
    padding: 2px 0; padding: 0.51282vw 0;
  }
  #search-modal-form .checkbox label {
    padding-left: 25px; padding-left: 6.41025vw;
    font-size: 14px; font-size: 3.58974vw;
  }
  #search-modal-form .search-modal-checkbox-list-small .checkbox label {
    font-size: 12px; font-size: 3.07692vw;
    padding-left: 22px; padding-left: 5.64102vw;
  }
  #search-modal-form .checkbox label::before {
    width: 16px; width: 4.10256vw;
    height: 16px; height: 4.10256vw;
    border-radius: 3px; border-radius: 0.76923vw;
    top: 2px; top: 0.51282vw;
    left: 0;
  }
  #search-modal-form .search-modal-checkbox-list-small .checkbox label::before {
    width: 14px; width: 3.58974vw;
    height: 14px; height: 3.58974vw;
  }
  #search-modal-form .checkbox label::after {
    width: 6px; width: 1.53846vw;
    height: 10px; height: 2.56410vw;
    border-width: 0 2px 2px 0; border-width: 0 0.51282vw 0.51282vw 0;
    top: 3px; top: 0.76923vw;
    left: 5px; left: 1.28205vw;
  }
  #search-modal-form .search-modal-checkbox-list-small .checkbox label::after {
    width: 4px; width: 1.02564vw;
    height: 8px; height: 2.05128vw;
    top: 4px; top: 1.02564vw;
    left: 5px; left: 1.28205vw;
  }
}



.search-modal-accordion+.search-modal-accordion {
  margin-top: 16px;
}

.search-modal-accordion-header input[type="checkbox"],
.search-modal-accordion-facility-list-row input[type="checkbox"] {
  display: none;
}
.search-modal-accordion-header label,
.search-modal-accordion-facility-list-row label {
  position: relative;
  display: inline-block;
}
.search-modal-accordion-header label::before,
.search-modal-accordion-header label::after,
.search-modal-accordion-facility-list-row label::before,
.search-modal-accordion-facility-list-row label::after {
  content: "";
  display: block;
  position: absolute;
}
.search-modal-accordion-header label::before,
.search-modal-accordion-facility-list-row label::before {
  -webkit-transition: .3s;
  transition: .3s;
}
.search-modal-accordion-header label::after,
.search-modal-accordion-facility-list-row label::after {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}
.search-modal-accordion-header label::before,
.search-modal-accordion-facility-list-row label::before {
  border: solid 1px #C6C6C6;
  background-color: #fff;
  border-radius: 3px;
  left: 0;
}
.search-modal-accordion-header label::after,
.search-modal-accordion-facility-list-row label::after {
  border-style: solid;
  border-color: #fff;
  border-width: 0 0 2px 2px;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.search-modal-accordion-header input:checked+label::before,
.search-modal-accordion-facility-list-row input:checked+label::before {
  background-color: #00005C;
  border-color: #00005C;
}
.search-modal-accordion-header input:checked+label::after,
.search-modal-accordion-facility-list-row input:checked+label::after {
  opacity: 1;
}

.search-modal-accordion-header {
  position: relative;
  background-color: #FAFAFA;
  padding: 8px 10px;
}
.search-modal-accordion-header label {
  padding-left: 24px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.search-modal-accordion-header label::before {
  width: 16px;
  height: 16px;
  top: 2px;
}
.search-modal-accordion-header label::after {
  width: 10px;
  height: 6px;
  top: 5px;
  left: 3px;
}
.search-modal-accordion-header input.half-check+label::before {
  background-color: #00005C;
  border-color: #00005C;
}
.search-modal-accordion-header input.half-check+label::after {
  opacity: 1;
  border-width: 0 0 2px 0;
  width: 8px;
  height: 2px;
  top: 8px;
  left: 5px;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.search-modal-accordion-toggle {
  display: block;
  border: solid 1px #00005C;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #fff;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.search-modal-accordion-toggle::before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  border-style: solid;
  border-color: #00005C;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 1.5px;
  left: 0;
  margin: auto;
}
.search-modal-accordion.active .search-modal-accordion-toggle::before {
  bottom: -1.5px;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.search-modal-accordion-body {
  overflow: hidden;
  height: 0;
}
.search-modal-accordion.active .search-modal-accordion-body {
  height: auto;
}
.search-modal-accordion-facility-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 12px 0 0 20px;
  gap: 10px 20px;
}
.search-modal-accordion-facility-list-row label {
  padding-left: 22px;
  font-size: 12px;
  line-height: 1.5;
}
.search-modal-accordion-facility-list-row label::before {
  width: 14px;
  height: 14px;
  top: 2px;
}
.search-modal-accordion-facility-list-row label::after {
  width: 8px;
  height: 4px;
  top: 6px;
  left: 3px;
}

@media screen and (max-width: 767px) {
  .search-modal-accordion+.search-modal-accordion {
    margin-top: 16px; margin-top: 4.10256vw;
  }

  .search-modal-accordion-header label::before,
  .search-modal-accordion-facility-list-row label::before {
    border-radius: 3px; border-radius: 0.76923vw;
  }
  .search-modal-accordion-header label::after,
  .search-modal-accordion-facility-list-row label::after {
    border-width: 0 0 2px 2px; border-width: 0 0 0.51282vw 0.51282vw;
  }

  .search-modal-accordion-header {
    padding: 8px 10px; padding: 2.05128vw 2.56410vw;
  }
  .search-modal-accordion-header label {
    padding-left: 24px; padding-left: 6.15384vw;
    font-size: 14px; font-size: 3.58974vw;
    line-height: 20px; line-height: 5.12820vw;
    cursor: pointer;
  }
  .search-modal-accordion-header label::before {
    width: 16px; width: 4.10256vw;
    height: 16px; height: 4.10256vw;
    top: 2px; top: 0.51282vw;
  }
  .search-modal-accordion-header label::after {
    width: 10px; width: 2.56410vw;
    height: 6px; height: 1.53846vw;
    top: 5px; top: 1.28205vw;
    left: 3px; left: 0.76923vw;
    
  }
  .search-modal-accordion-header input.half-check+label::after {
    border-width: 0 0 2px 0; border-width: 0 0 0.51282vw 0;
    width: 8px; width: 2.05128vw;
    height: 2px; height: 0.51282vw;
    top: 8px; top: 2.05128vw;
    left: 5px; left: 1.28205vw;
  }
  .search-modal-accordion-toggle {
    width: 14px; width: 3.58974vw;
    height: 14px; height: 3.58974vw;
    right: 10px; right: 2.56410vw;
  }
  .search-modal-accordion-toggle::before {
    width: 3px; width: 0.76923vw;
    height: 3px; height: 0.76923vw;
    bottom: 1.5px; bottom: 0.38461vw;
  }

  .search-modal-accordion-facility-list {
    display: flex;
    flex-direction: column;
    padding: 10px 0 0 20px; padding: 2.56410vw 0 0 5.12820vw;
    gap: 10px 20px; gap: 2.56410vw 5.12820vw;
  }
  .search-modal-accordion-facility-list-row label {
    padding-left: 22px; padding-left: 5.64102vw;
    font-size: 12px; font-size: 3.07692vw;
  }
  .search-modal-accordion-facility-list-row label::before {
    width: 14px; width: 3.58974vw;
    height: 14px; height: 3.58974vw;
    top: 2px; top: 0.51282vw;
  }
  .search-modal-accordion-facility-list-row label::after {
    width: 8px; width: 2.05128vw;
    height: 4px; height: 1.02564vw;
    top: 6px; top: 1.53846vw;
    left: 3px; left: 0.76923vw;
  }
}

.search-modal-accordion-header {
  cursor: pointer;
}
.search-modal-accordion-header label {
  pointer-events: none;
}
.search-modal-accordion-header label::before,
.search-modal-accordion-header label::after {
  pointer-events: all;
}








.search-modal-datetime-radio {
  font-size: 12px;
  line-height: 1.5;
}
.search-modal-datetime-radio input[type="radio"] {
  display: none;
}
.search-modal-datetime-radio label {
  position: relative;
  display: inline-block;
  padding: 0 0 1px 21px;
  margin-bottom: 5px;
}
.search-modal-datetime-radio label::before,
.search-modal-datetime-radio label::after {
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.search-modal-datetime-radio label::before {
  width: 14px;
  height: 14px;
  border: solid 1px #C6C6C6;
  left: 0;
}
.search-modal-datetime-radio label::after {
  width: 10px;
  height: 10px;
  background-color: #00005C;
  left: 3px;
  opacity: 0;
  -webkit-transition: .3s;
  transition: .3s;
}
.search-modal-datetime-radio input[type="radio"]:checked+label::after {
  opacity: 1;
}
.search-modal-datetime-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: -30px;
}
.search-modal-datetime-group input[type="date"] {
  min-width: 192px;
  max-width: 192px;
  margin-right: 6px!important;
}
.disabled .search-modal-datetime-group {
  display: none;
}

@media screen and (max-width: 767px) {
  .search-modal-datetime-radio {
    font-size: 12px; font-size: 3.07692vw;
  }
  .search-modal-datetime-radio label {
    padding: 0 0 1px 21px; padding: 0 0 0.25641vw 5.38461vw;
    margin-bottom: 5px; margin-bottom: 1.28205vw;
  }
  .search-modal-datetime-radio label::before {
    width: 14px; width: 3.58974vw;
    height: 14px; height: 3.58974vw;
  }
  .search-modal-datetime-radio label::after {
    width: 10px; width: 2.56410vw;
    height: 10px; height: 2.56410vw;
    left: 3px; left: 0.76923vw;
  }
  .search-modal-datetime-group {
    flex-wrap: wrap;
    gap: 6px 8px; gap: 1.53846vw 2.05128vw;
    margin-right: 0;
  }
  .search-modal-datetime-group input[type="date"] {
    max-width: 100%;
    margin-right: 0px!important;
  }
  .search-modal-datetime-group select {
    flex-basis: 40%;
    flex-grow: 2;
  }
}



.search-modal-layout-list {
  display: flex;
  gap: 16px;
  margin: 14px -30px 0 0;
}
.search-modal-layout-list li {
  flex-basis: var(--size-90);
}
.search-modal-layout-list li input[type="checkbox"] {
  display: none;
}
.search-modal-layout-list li label {
  position: relative;
  display: block;
  border: solid 3px #fff;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.3);
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-flow: column-reverse;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
  box-sizing: border-box;
  height: var(--size-115);
  justify-content: space-between;
}
.search-modal-layout-list li label > img {
  width: 100%;
  height: auto;
}

.search-modal-layout-list li label .c_text {
  display: flex;
  font-size: 1.2rem;
  text-align: left;
  padding-top: 6px;
  padding-bottom: 7px;
  @include line-height(15,12);
  padding-left: 7px;
  padding-right: 7px;
  align-items: center;
  height: var(--size-45);
  justify-content: center;
  @include sp {
    @include fz-vw(12);
    height: var(--size-50);
  }
}
.search-modal-layout-list li label .c_text span {
  position: relative;
  display: block;
  width: 16px;
  margin-right: var(--size-5);
  // height: 30px;
  // background-color: #fff;
  -webkit-transition: .3s;
  transition: .3s;
}
.search-modal-layout-list li label .c_text span::before,
.search-modal-layout-list li label .c_text span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  -webkit-transition: .3s;
  transition: .3s;
}
.search-modal-layout-list li label .c_text span::before {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: solid 1px #C6C6C6;
  border-radius: 3px;
  box-sizing: border-box;
  top: 0.1em;
}
.search-modal-layout-list li label .c_text span::after {
  top: -2px;
  width: 8px;
  height: 4px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 0 2px 2px;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.search-modal-layout-list li label:hover,
.search-modal-layout-list li input:checked+label {
  border-color: #00005C;
}
.search-modal-layout-list li label:hover {
  @include sp {
    border-color: #FFF;
  }
}
.search-modal-layout-list li input:checked+label .c_text {
    background-color: #F0F0F0;
}
.search-modal-layout-list li label:hover > span,
.search-modal-layout-list li input:checked+label > span {
  background-color: #F0F0F0;
}
.search-modal-layout-list li input:checked+label span::before {
  background-color: #00005C;
  border-color: #00005C;
}
.search-modal-layout-list li > input:checked+label span::after {
  opacity: 1;
}

.search-modal-layout-list li input:disabled+label {
  opacity: 0.4;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .search-modal-layout-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 13px; gap: 2.56410vw 3.33333vw;
    margin: 20px 0 0 0; margin-top: 5.12820vw;
  }
  .search-modal-layout-list li label {
    border-radius: 3px; border-radius: 0.76923vw;
  }
  .search-modal-layout-list li label > img {
    width: 90%;
    margin-bottom: 4px; margin-bottom: 1.02564vw;
  }
  .search-modal-layout-list li label > span {
    width: 100%;
    height: 30px; height: 7.69230vw;
  }
  .search-modal-layout-list li label > span::before {
    width: 14px; width: 3.58974vw;
    height: 14px; height: 3.58974vw;
    border-radius: 3px; border-radius: 0.76923vw;
  }
  .search-modal-layout-list li label > span::after {
    top: -2px; top: -0.51282vw;
    width: 8px; width: 2.05128vw;
    height: 4px; height: 1.02564vw;
    border-width: 0 0 2px 2px; border-width: 0 0 0.51282vw 0.51282vw;
  }
}



#search-modal-form-control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  // margin: 30px 0 55px 0;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding-top: 20px;
  padding-bottom: 30px;
  background: rgba(255,255,255,0.75);
}
#search-modal-form-control button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 64px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  -webkit-transition: .3s;
  transition: .3s;
  @include font-weight(semi);
  @include sp {
    @include fz-vw(14);
    width: calc(296 / 390 * 100vw);
    height: calc(52 / 390 * 100vw);
  }
}
#search-modal-form-submit {
  background-color: #AA8919;
  border-color: #AA8919;
  color: #fff;
  font-size: 19px;
  line-height: 30px;
  gap: 10px;
}
#search-modal-form-submit::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url(../../img/icon/icon_sm_search-brown.svg) center no-repeat;
  background-size: contain;
  filter: brightness(0) invert(1);
  -webkit-transition: .3s;
  transition: .3s;
}
#search-modal-form-submit:hover {
  background-color: #fff;
  color: #AA8919;
}
#search-modal-form-submit:hover::before {
  filter: brightness(1) invert(0);
}
#search-modal-form-reset {
  background-color: #fff;
  border-color: #272727;
  color: #272727;
  font-size: 16px;
  line-height: 22px;
}
#search-modal-form-reset:hover {
  background-color: #272727;
  color: #fff;
}

@media screen and (max-width: 767px) {
  #search-modal-form-control {
    flex-direction: column;
    gap: 12px;
    margin: 0;
  }
}