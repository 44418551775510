@use "sass:math"; 
@import '../setting/setting';
@import '../setting/responsive';

$base_font: "Noto Sans JP", sans-serif;
$poppins_font: "Poppins", sans-serif;
$oswald_font: "Oswald", sans-serif;
$cg_font: "Cormorant Garamond", serif;


// color setting
$color_main : #00005C;
$color_accent : #AA8919;
$color_txt01 : #272727;
$color_txt02 : #747474;
$color_line : #C6C6C6;
$color_gray01 : #F0F0F0;
$color_gray02 : #FAFAFA;


// base font size
@mixin font-size($size, $base: 10) {
	font-size: $size + px;
	font-size: calc($size / $base) + rem;
}

// font-weight
@mixin font-weight($weight) {
	$weights: (
		thin: 100,
		light: 300,
		normal: 400,
		medium: 500,
		semi: 600,
		bold: 700,
		extra: 800,
		black: 900,
	);
	$output: $weight;
	@if map-has-key($weights, $weight) {
		$output: map-get($weights, $weight);
	}
	font-weight: $output;
}

//px-vw;
@function get_vw($size, $viewport: 390) {
	$rate:  calc(100 / $viewport);
  @return $rate * $size * 1vw;
}
@mixin fz-vw($font_size) {
	font-size: get_vw($font_size);
}
@mixin img-vw($img_size) {
	width: get_vw($img_size);
}
@mixin p-vw($property, $size) {
	#{$property}: get_vw($size);
}

// flex
@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}
@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}
@mixin flex-wrap($value: nowrap) {
	-webkit-flex-wrap: $value;
	-moz-flex-wrap: $value;
	@if $value == nowrap {
		-ms-flex-wrap: none;
	} @else {
		-ms-flex-wrap: $value;
	}
	flex-wrap: $value;
}
@mixin flex-flow($values: (row nowrap)) {
	-webkit-flex-flow: $values;
	-moz-flex-flow: $values;
	-ms-flex-flow: $values;
	flex-flow: $values;
}
@mixin order($int: 0) {
	-webkit-box-ordinal-group: $int + 1;
	-webkit-order: $int;
	-moz-order: $int;
	-ms-flex-order: $int;
	order: $int;
}
@mixin flex-grow($int: 0) {
	-webkit-box-flex: $int;
	-webkit-flex-grow: $int;
	-moz-flex-grow: $int;
	-ms-flex-positive: $int;
	flex-grow: $int;
}
@mixin flex-shrink($int: 1) {
	-webkit-flex-shrink: $int;
	-moz-flex-shrink: $int;
	-ms-flex-negative: $int;
	flex-shrink: $int;
}
@mixin flex-basis($value: auto) {
	-webkit-flex-basis: $value;
	-moz-flex-basis: $value;
	-ms-flex-preferred-size: $value;
	flex-basis: $value;
}
@mixin flex($fg: 1, $fs: null, $fb: null) {
	$fg-boxflex: $fg;
	@if type-of($fg) == 'list' {
		$fg-boxflex: nth($fg, 1);
	}
	-webkit-box-flex: $fg-boxflex;
	-webkit-flex: $fg $fs $fb;
	-moz-box-flex: $fg-boxflex;
	-moz-flex: $fg $fs $fb;
	-ms-flex: $fg $fs $fb;
	flex: $fg $fs $fb;
}
@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value;
}
@mixin align-items($value: stretch) {
	@if $value == flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start;
	} @else if $value == flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $value;
		-ms-flex-align: $value;
	}
	-webkit-align-items: $value;
	-moz-align-items: $value;
	align-items: $value;
}
@mixin align-self($value: auto) {
	-webkit-align-self: $value;
	-moz-align-self: $value;
	@if $value == flex-start {
		-ms-flex-item-align: start;
	} @else if $value == flex-end {
		-ms-flex-item-align: end;
	} @else {
		-ms-flex-item-align: $value;
	}
	align-self: $value;
}
@mixin align-content($value: stretch) {
	-webkit-align-content: $value;
	-moz-align-content: $value;
	@if $value == flex-start {
		-ms-flex-line-pack: start;
	} @else if $value == flex-end {
		-ms-flex-line-pack: end;
	} @else {
		-ms-flex-line-pack: $value;
	}
	align-content: $value;
}

// text-justify
@mixin justify {
	text-align: justify;
	text-justify: inter-ideograph;
}

// line-height
@mixin line-height($psd-size , $font-size) {
	line-height: math.div(calc($psd-size / $font-size * 10000), 10000) 
}

// absolute image
@mixin absolute-img {
	content: '';
	position: absolute;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}

// gradation
@mixin gradation($topColor, $bottomColor) {
	background: mix($topColor, $bottomColor, 50%);
	background: linear-gradient($topColor, $bottomColor);
}

// object-fit
@mixin object-fit($type:cover, $x:center, $y:center){
	object-fit: $type;
	object-position: $x $y;
	font-family: 'object-fit: #{$type}; object-position: #{$x} #{$y};';
	background-size: $type !important;
}